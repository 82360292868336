import { TextField, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getListIssuesWithServicesOnGrids, getGridData, getListIssuesOnPeriod, getMaterialIssue } from "../api/api";
import Tabela from "./Tabela";
import Box from "@mui/material/Box";
import Loading from "./Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

function Form() {
  const [inicio, setInicio] = useState();
  const [fim, setFim] = useState();
  const [submit, setSubmit] = useState(false);

  const [list, setList] = useState([]);

  const [materialId, setMaterialId] = useState("635b1b90e469cf99621ee27e");
  const [servicoId, setServicoId] = useState("636136ece469cf9962281837");

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [maxProgress, setMaxProgress] = useState(0);


  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();

  function Progressbar(props) {
    const normalize = (value) => ((value) * 100 / (maxProgress));
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={normalize(props.value)} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{Math.round(normalize(props.value))+'%'}</Typography>
          </Box>
        </Box>
      );
  }

  useEffect(() => {
    if(progress === maxProgress) {
      setLoading(false);
      console.log("effect : " + progress);
      setProgress(0);
      setMaxProgress(100);
    }
  }, [inicio, fim, list, progress, maxProgress]);

  function startDateHandler(date){
    setStartDate(date);
    setInicio(date.toISOString().split('T')[0]);
  }

  function endDateHandler(date){
    setFim(date.toISOString().split('T')[0]);
    setEndDate(date);
  }


  function onSubmitHandler() {
    setList([]);
    var temp = [];
    var i = 1;
    
    getListIssuesOnPeriod(inicio, fim)
    .then((result) => {
      setMaxProgress(result.data.length);
      console.log("Max: ", result.data.length);
      result.data.map((issue) =>
        getMaterialIssue(issue.key)
          .then((resultMaterial) => {
            if(resultMaterial.data != null) {
              //console.log(resultMaterial.data);
              if(resultMaterial.data[0].columns.materiais != null) {
                //console.log(resultMaterial.data);
                issue['material'] = resultMaterial.data;
                temp.push(issue);
                setList([...temp]);
              }
            }
            console.log("i: ", i);
           setProgress(i++);
          })
          .catch ((errorMaterial) => { 
            console.log("ERROR");
            console.log("i: ", i);
            
            setProgress(i++);
          })
      )
    })
    .catch((error) => {
      //console.log(error);
    });


   /* getMaterialIssue('PCDF-774')
    .then((result) => {
      console.log(result.data.columns);
    })
    .catch ((error) => {
      console.log(error);
    });

    setList([]);
    var temp = [];
    getListIssuesOnPeriod(inicio, fim)
    .then((result) => {
      console.log(result.data);
      
    })
    .catch((error) => {
      console.log(error);
    })

    /*
    getListIssuesWithServicesOnGrids(inicio, fim)
      .then((result) => {
        result.data.map((issue) =>
         getGridData("636136ece469cf9962281837", issue.key)
            .then((res) => {
              
              issue["servico"] = res.data;
              
              temp.push(issue);
              setList([...temp]);
              setLoading(false);
             
            })
            .catch((e) => {
              console.log(e);
            })
        );
      })
      .catch((error) => {
        console.log(error);
      });
   */
  }

  return (
    <div>
      <br />
      <br />
      <div>
        <label className="">Medição de <b>Materiais</b> Aplicados</label>
      </div>
      <br />
      <form
        onSubmit={(event) => {
          setLoading(true);
          event.preventDefault();
          onSubmitHandler();
          setSubmit(true);
        }}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
        >
          <DatePicker selected={startDate} onChange={(date) => startDateHandler(date)} />
          <br/>
          <br/>
          <DatePicker selected={endDate} onChange={(date) => endDateHandler(date)} />

          <br/>
          <br/>
        </Box>
        <br />
        <br />
        <Button type="submit" variant="contained">
          Consultar
        </Button>
      </form>
      <br />
      {loading && <Progressbar value={progress}/>}
      <br />
      <br />
      {loading && <Loading />}
      <br />
      { submit && <Tabela dados={list} /> }

      <br />
    </div>
  );
}

export default Form;
