import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExportExcel from "./ExportExcel";


function Tabela({ dados }) {
  const [info, setInfo] = useState();

  useEffect(() => {
    setInfo(dados);
    //console.log("tabela");
    console.log(dados);
  }, [dados]);

  function onMaterialHandler(material){
    var total = 0;
    for(var i = 0; i < material.length ; i++){
      total = total + material[i].columns.vlr;
    }
    return total.toFixed(2);
  }

  function onMaterialNameHandler(m){
    //console.log("material name handler");
    //console.log(m);
    if (m.columns.materiais != null) {
      
      return m.columns.materiais.label;
      
    }
  }

  function onMaterialVlrHandler(m){
    console.log("valor handler");
    console.log(m);
    if (m.columns.materiais !== null) {

      if(m.columns.materiais.valor_unitario !== null) {
        return m.columns.materiais.valor_unitario.toFixed(2);
      }
      //console.log(material);
    }
  }

  function onMaterialUndHandler(m){
    //console.log("material unidade de medida");
    //console.log(m);
    if (m.columns.materiais != null) {
      //console.log(material);
      return m.columns.materiais.unidade;     
    }
  }

  function onComplexo(issue){
    if(issue.fields.customfield_10065 != null) {
      //console.log(issue);
      
      

      return issue.fields.customfield_10065.value + " / " + getEdificio(issue.fields);
    }
    
  }

  function getEspecialidade(issue){
    if(issue.fields.customfield_10229 != null){
      return issue.fields.customfield_10229.value;
    }
  }

  function getEdificio(complexo){
    //customfield_10081 -> SGON
    //customfield_10079 -> PCDF
    //customfield_10078 -> DASG/DITRAN
    //customfield_10080 -> REGIONAL NORTE
    //customfield_10082 -> UNIDADES DIVERSAS
    //console.log(complexo);
    
    switch (complexo.customfield_10065.value){

      
      case "COMPLEXO PCDF": return complexo.customfield_10079.value;

      case "COMPLEXO SGON": return complexo.customfield_10081.value;

      case "COMPLEXO DASG/DITRAN": return complexo.customfield_10078.value;

      case "COMPLEXO REGIONAL NORTE": return complexo.customfield_10080.value;

      case "UNIDADES DIVERSAS": return complexo.customfield_10082.value;

      default: return "";
      
    }

  }

  function dateHandler(date) {

    if(date != null){
      var newDate = new Date(date);
      return newDate.toLocaleDateString();
    }

  }


    
  return (
    <div>
      <ExportExcel fileName={"Relatorio de Materiais"} table={document.getElementsByTagName('table')[0]}/>
      <TableContainer component={Paper} elevation={20}>
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="">Data de Criação</TableCell>
              <TableCell align="">Data de Resolução</TableCell>
              <TableCell align="">Nº OS</TableCell>
              <TableCell align="">Local</TableCell>
              <TableCell align="">Especialidade</TableCell>
              <TableCell align="">Resumo</TableCell>
              <TableCell align="">Material</TableCell>
              <TableCell align="">Qtd.</TableCell>
              <TableCell align="">UND.</TableCell>
              <TableCell>Vlr. Unit.</TableCell>
              <TableCell>Vlr. Total</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Tipo</TableCell>
            </TableRow>
          </TableHead>
          {dados.map((issue) =>
              <TableBody>
                {issue.material.map((m) =>     
                    <TableRow>
                      <TableCell>{dateHandler(issue.fields.created)}</TableCell>
                      <TableCell>{dateHandler(issue.fields.resolutiondate)}</TableCell>
                      <TableCell>{issue.key}</TableCell>
                      <TableCell>{onComplexo(issue)}</TableCell>
                      <TableCell>{getEspecialidade(issue)}</TableCell>
                      <TableCell>{issue.fields.summary}</TableCell>
                      <TableCell>{onMaterialNameHandler(m)}</TableCell>
                      <TableCell>{m.columns.qtd}</TableCell>
                      <TableCell>{onMaterialUndHandler(m)}</TableCell>
                      <TableCell>{"R$ "+ onMaterialVlrHandler(m)}</TableCell>
                      <TableCell>{"R$ "+ (onMaterialVlrHandler(m) * m.columns.qtd).toFixed(2) }</TableCell>
                      <TableCell align="right">{issue.fields.status.name}</TableCell>
                      <TableCell align="right">{issue.fields.issuetype.name}</TableCell>
                    </TableRow>
                  )}
              </TableBody>
            )}
        </Table>
      </TableContainer>
    </div>
  );
}
export default Tabela;
